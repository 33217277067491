import { Footer } from '@almbrand/footer';
import { TrackingEvent } from '@almbrand/trackinghooks';

export const FooterComponent: React.FC<FooterReference> = ({
	linkColumns,
	policyLinks,
	companyAddress,
	companyCity,
	companyName,
	companyVatNumber,
	logoImage,
	logoUrl,
}) => {
	const footerLinks = linkColumns.map((linkColumn) => ({
		header: linkColumn.header,
		links: linkColumn.links.map((link) => ({
			text: link.text,
			url: link.url,
			target: link.target,

			trackingEvent: {
				event: 'trackNavigation',
				navigationAction: link?.text ?? '',
				navigationDestination: link?.url,
				navigationType: 'footer',
			} as TrackingEvent,
		})),
	}));

	return (
		<Footer
			companyInfo={{
				name: companyName,
				address: companyAddress,
				city: companyCity,
				cvr: companyVatNumber,
			}}
			linkColumns={footerLinks}
			policyLinks={policyLinks.map((link) => ({ text: link.text, url: link.url }))}
			logo={logoImage?.url}
			logoUrl={logoUrl?.url}
		/>
	);
};
